import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dashboard } from '../Images';
import Modal from 'react-modal';

const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBookDemo = () => {
    setIsModalOpen(true);
  };

  // Custom styles for the modal
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '1200px', // Set a fixed width for the modal
      maxWidth: '90%', // Ensure it's responsive on smaller screens
      padding: '0', // Remove default padding
      border: 'none', // Remove default border
      borderRadius: '8px', // Add rounded corners
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
      zIndex: 1000, // Ensure modal appears above other content
    }
  };

  return (
    <>
      <div className="banner-area" id="home">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center">
                <div className="fs-50 fw-bold">
                  YOUR AI PRODUCT MARKETING TEAMMATE
                  <span className="text-gradin"></span>
                </div>
                <div
                  className="fs-18 text-main pt-3 pb-3 mx-auto"
                  style={{ maxWidth: '580px', lineHeight: '30px' }}
                >
                  2X the productivity of your Entire Product Marketing Team
                </div>
                <div className="col-lg-12">
                  <div className="text-center mt-4 pt-2">
                    <button onClick={handleBookDemo} className="book-btn">
                      Book a Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel="Book a Demo Modal"
      >
        {/* Add close button */}
        <div style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          zIndex: 1,
          cursor: 'pointer',
          background: 'white',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }} onClick={() => setIsModalOpen(false)}>
          ✕
        </div>

        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1uBPu92syg8OdvAjV2nWUXsjR6HSgMIl2mI-QAflL2D7BHR24RH0ZUqIaf18YaDcmvCXvjDjwU?gv=true"
          style={{ 
            width: '100%', 
            height: '600px', 
            border: 'none',
            borderRadius: '8px'
          }}
          title="Book a Demo"
        />
      </Modal>

      <div className="dashboard-img">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 mt-4">
              <Dashboard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;