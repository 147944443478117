import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Login.scss';  // Import the SCSS file

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if auth token is set
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      navigate('/dashboard');  // Redirect to dashboard if token is present
    }
  }, [navigate]);

  const handleLogin = (event) => {
    event.preventDefault();
    fetch(`${window.location.origin}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password, next_url: "/dashboard" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.redirect_url) {
          localStorage.setItem('authToken', 'true');  // Set logged-in state
          navigate(data.redirect_url);  // Redirect to the given URL
        } else if (data.error) {
          alert(data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred. Please try again.");
      });
  };

  return (
    <div className="full-page-container">
      <div className="login-container">
        <h2>InsightPath AI</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <input type="submit" value="Login" />
        </form>
      </div>
    </div>
  );
};

export default Login;
