import React from 'react'
import { Features2, Features3 } from '../Images'

const Features = () => {
    return (
        <div className="features-area" id='features'>
            <div className="container">
                <div className="row">

                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <div className="fs-50 fw-bold">Upcoming Features </div>
                            <div className="fs-18 text-main pt-3 pb-3 mx-auto" style={{ maxWidth: "180px", lineHeight: "30px" }}>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row row-gap-4">
                    {features.map((item) => (
                        <div className="col-lg-12 features-content mt-4" key={item}>
                            <div className="features-item d-flex align-items-center justify-content-between">
                                <div className='align-self-center'>
                                    <div className="fs-40 fw-bold lh-sm pb-2">{item.title}</div>
                                    <div className="fs-18 text-main lh-sm">{item.desc}</div>
                                </div>
                                <div className="img-container">
                                    {item.img}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

const features = [
    {
        img: <Features2 />,
        title: "Easy Integration",
        desc: "Seamlessly integrate into existing workflow and  create the InsightPath knowledge hub.",
    },
    {
        img: <Features3 />,
        title: "Enterprise Ready Security",
        desc: "SOC 2 Type 2 Certification, GDPR compliance.",
    },
]

export default Features
