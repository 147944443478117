import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Images';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import UniversalModal from './UniversalModal';
import PrivacyPolicyContent from './PrivacyPolicyContent';
import TermsContent from './TermsContent';

const Footer = () => {
    const [modalConfig, setModalConfig] = useState({
        isOpen: false,
        title: '',
        content: null
    });

    const openModal = (type) => {
        if (type === 'privacy') {
            setModalConfig({
                isOpen: true,
                title: 'InsightPath Privacy Policy',
                content: <PrivacyPolicyContent />
            });
        } else if (type === 'terms') {
            setModalConfig({
                isOpen: true,
                title: 'Terms of Service',
                content: <TermsContent />
            });
        }
    };

    const closeModal = () => {
        setModalConfig({
            isOpen: false,
            title: '',
            content: null
        });
    };

    return (
        <>
            <footer className='footer-area mt-5'>
                <div className="container">
                    <div className="footer-widget">
                        {wedgetData.map((widget, index) => (
                            <div key={index} className="footer-widget-item">
                                <div className="fs-18 fw-medium">{widget.title}</div>
                                <ul className="space-y-2">
                                    {widget.links.map((link, i) => (
                                        <li key={i}>
                                            {link.text === "Privacy Policy" ? (
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    openModal('privacy');
                                                }}>Privacy Policy</a>
                                            ) : link.text === "Terms And Conditions" ? (
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    openModal('terms');
                                                }}>Terms of Service</a>
                                            ) : (
                                                <Link to={link.url}>{link.text}</Link>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-16 pt-8 border-t border-blue-800">
                        <p className="text-center py-4">&copy; 2024 InsightPath. All rights reserved.</p>
                </div>
                    
            </footer>

            <UniversalModal
                isOpen={modalConfig.isOpen}
                onClose={closeModal}
                title={modalConfig.title}
            >
                {modalConfig.content}
            </UniversalModal>
        </>
    );
};
const wedgetData = [
    // {
    //     title: "QUICK LINKS",
    //     links: [
    //         { text: "About Us", url: "" },
    //         { text: "Features", url: "" },
    //         { text: "FAQ", url: "" },
    //     ]
    // },
    {
        title: "InsightPath",
        links: [
            { text: "San Jose, CA", url: "" },
            // { text: "Login", url: "" },
            // { text: "Blog", url: "" },
            // { text: "News", url: "" },
        ]
    },
    {
        title: "Legal",
        links: [
            { text: "Terms And Conditions", url: "" },
            { text: "Privacy Policy", url: "" },
            { text: "", url: "" },
            { text: "", url: "" },

        ]
    }
];
export default Footer;