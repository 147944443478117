import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Logo } from "./Images";
import UniversalModal from "./UniversalModal";
import ContactFormContent from "./ContactFormContent";

const CustomNav = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const closeOffcanvas = () => setShowOffcanvas(false);
  const openContactModal = (e) => {
    e.preventDefault();
    setShowContactModal(true);
    closeOffcanvas();
  };
  
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar expand="lg" className={`navbar-main ${isScrolled ? "scrolled" : ""}`}>
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <Logo />
            </Link>
          </Navbar.Brand>
          <div className="d-flex align-items-center gap-3">
            <Navbar.Toggle
              aria-controls="offcanvasNavbar-expand-lg"
              onClick={() => setShowOffcanvas((prev) => !prev)}
            />
          </div>
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={closeOffcanvas}
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                <Link to="" onClick={closeOffcanvas}>
                  <Logo />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center align-items-center flex-grow-1 pe-3 NavList">
                <NavLinkComponent closeOffcanvas={closeOffcanvas} />
              </Nav>
              <div className="d-lg-flex d-grid text-center align-items-center gap-3 mt-4 mt-md-0">
                <Link to="/login" className="nav-link">Login</Link>
                <button 
                  onClick={openContactModal} 
                  className="nav-link border-0 bg-transparent p-0"
                >
                  Contact Us
                </button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {showContactModal && (
        <div className="universal-modal-overlay">
          <div className="universal-modal-content">
            <button className="universal-modal-close" onClick={() => setShowContactModal(false)}>&times;</button>
            <h2 style={{ color: '#4a4ac7' }}>Contact Us</h2>
            <ContactFormContent />
          </div>
        </div>
      )}
    </>
  );
};

const NavLinkComponent = ({ closeOffcanvas }) => (
  <>
    {/* <a href="#home" className="nav-link" onClick={closeOffcanvas}>
      Home
    </a>
    <a href="#features" className="nav-link" onClick={closeOffcanvas}>
      Features
    </a>

    <a href="#testimonial" className="nav-link" onClick={closeOffcanvas}>
      Testimonials
    </a> */}
  </>
);

export default CustomNav;