import React, { useState } from 'react';

const ContactFormContent = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    message: ''
  });
  
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xdknveea', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setStatus('success');
        setFormData({ name: '', company: '', email: '', message: '' });
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div className="modal-body">
      <form
        onSubmit={handleSubmit}
        noValidate
        className="space-y-4"
      >
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="company"
            className="block text-sm"
          >
            Company
          </label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm"
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-sm"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
        </div>

        <div className="flex justify-end pr-2 mt-4">
                    <button
            type="submit"
            style={{
              backgroundColor: '#4a4ac7',
              color: 'white',
              padding: '8px 24px',
              borderRadius: '4px',
              fontSize: '14px'
            }}
            disabled={status === 'submitting'}
          >
            {status === 'submitting' ? 'Sending...' : 'Send Message'}
          </button>
        </div>

        {status === 'success' && (
          <div style={{ 
            marginTop: '1rem', 
            padding: '1rem', 
            backgroundColor: '#f0fdf4', 
            color: '#166534', 
            borderRadius: '0.25rem',
            border: '1px solid #bbf7d0'
          }}>
            Thank you for your message. We'll get back to you soon!
          </div>
        )}

        {status === 'error' && (
          <div style={{ 
            marginTop: '1rem', 
            padding: '1rem', 
            backgroundColor: '#fef2f2', 
            color: '#dc2626', 
            borderRadius: '0.25rem',
            border: '1px solid #fecaca'
          }}>
            There was an error sending your message. Please try again later.
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactFormContent;