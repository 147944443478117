import logo from "../Assets/images/logo.svg";
import whiteLogo from "../Assets/images/white-logo.png";
import dashboard from "../Assets/images/dashboard.png";
import logo1 from "../Assets/images/logo/1.png";
import logo2 from "../Assets/images/logo/2.png";
import logo3 from "../Assets/images/logo/3.png";
import logo4 from "../Assets/images/logo/4.png";
import logo5 from "../Assets/images/logo/5.png";
import logo6 from "../Assets/images/logo/6.png";
import logo7 from "../Assets/images/logo/7.png";
import process1 from "../Assets/images/process1.png";
import process2 from "../Assets/images/process2.png";
import process3 from "../Assets/images/process3.png";
import features1 from "../Assets/images/features1.png";
import features2 from "../Assets/images/features2.png";
import features3 from "../Assets/images/features3.png";
import review from "../Assets/images/review.png";
import user from "../Assets/images/user-pc.png";

export const Logo = () => <img src={logo} alt={logo} />;
export const WhiteLogo = () => <img src={whiteLogo} alt={whiteLogo} />;
export const Dashboard = () => <img src={dashboard} alt={dashboard} className="w-100" />;
export const Logo1 = () => <img src={logo1} alt={logo1} />;
export const Logo2 = () => <img src={logo2} alt={logo2} />;
export const Logo3 = () => <img src={logo3} alt={logo3} />;
export const Logo4 = () => <img src={logo4} alt={logo4} />;
export const Logo5 = () => <img src={logo5} alt={logo5} />;
export const Logo6 = () => <img src={logo6} alt={logo6} />;
export const Logo7 = () => <img src={logo7} alt={logo7} />;
export const Process1 = () => <img src={process1} alt={process1} />;
export const Process2 = () => <img src={process2} alt={process2} />;
export const Process3 = () => <img src={process3} alt={process3} />;  
export const Features1 = () => <img src={features1} alt={features1} />;  
export const Features2 = () => <img src={features2} alt={features2} />;  
export const Features3 = () => <img src={features3} alt={features3} />;  
export const Review = () => <img src={review} alt={review} />;  
export const UserImg = () => <img src={user} alt={user} />;
export const DownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
        <path d="M3.39816 4.21387C3.28481 4.21387 3.17143 4.16747 3.08498 4.07489L0.364533 1.15862C0.191477 0.973115 0.191477 0.672346 0.364533 0.486913C0.537519 0.30148 0.818044 0.30148 0.991114 0.486913L3.39816 3.06735L5.8053 0.487093C5.97829 0.30166 6.25885 0.30166 6.43182 0.487093C6.60496 0.672526 6.60496 0.973205 6.43182 1.15871L3.71142 4.075C3.62493 4.16756 3.51155 4.21387 3.39816 4.21387Z" fill="black" />
    </svg>
);
export const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M12.4988 6.59054H7.55392V11.5355H5.90561V6.59054H0.960693V4.94223H5.90561V-0.00268555H7.55392V4.94223H12.4988V6.59054Z" fill="white" />
    </svg>
);  