import React, { useState } from 'react'
import { Process1, Process2, Process3 } from '../Images'

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

const Process = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleBookDemo = () => {
        setIsModalOpen(true);
    };

    return (
        <div className="process-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <div className="fs-50 fw-bold">Tackle Your Product Information Challenges</div>
                            
                        </div>
                    </div>
                </div>
                <div className="row process-content row-gap-4 mt-4">
                    {processData.map((item, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="process-item">
                                <div className="process-img">{item.img}</div>
                                <div>
                                    <div className="fs-25 fw-bold lh-sm pb-2">
                                        {item.title}
                                    </div>
                                    <div className="fs-18 text-main lh-sm">
                                        {item.desc}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-12">
                        <div className="text-center mt-4 pt-2">
                            <button onClick={handleBookDemo} className="book-btn">Book a Demo</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <iframe
                    src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1uBPu92syg8OdvAjV2nWUXsjR6HSgMIl2mI-QAflL2D7BHR24RH0ZUqIaf18YaDcmvCXvjDjwU?gv=true"
                    style={{ width: '100%', height: '600px', border: 'none' }}
                    title="Book a Demo"
                />
            </Modal>
        </div>
    )
}

const processData = [
    {
        img: <Process1 />,
        title: "Struggling to Keep Up with Product Updates?",
        desc: "Create docs like battle cards, FAQs or tailor your presentation with relevant case studies in minutes.",
    },
    {
        img: <Process2 />,
        title: "Can't Find Answers Quickly?",
        desc: "Get accurate answers for all types of questions, go from queries to actions in seconds.",
    },
    {
        img: <Process3 />,
        title: "Frustrated by Inconsistent Information?",
        desc: "Easily spot existing documents that need updates after every upload, allowing for quick edits.",
    },
]

export default Process