import React from "react";
import { useLocation } from "react-router-dom";
import CustomNav from "./CustomNav";
import Footer from "./Footer";

const Layout = ({ children }) => { 

  return (
    <div>
      <header>
        <CustomNav />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;