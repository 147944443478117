// TermsContent.jsx
const TermsContent = () => {
    return (
      <div className="modal-body">
        <p className="mb-4">
          These Terms of Use ("Terms") govern your access to and use of the
          InsightPath website, services, and applications (collectively, the
          "Services"). By accessing or using the Services, you agree to be bound
          by these Terms. If you do not agree to these Terms, you may not access
          or use the Services.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">1. Introduction</h3>
        <p className="mb-4">
          InsightPath ("we", "us", or "our") is an AI-powered platform that
          analyzes sales enablement content to generate answers and insights for
          go-to-market teams.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">2. User Eligibility</h3>
        <p className="mb-4">
          To use the Services, you must be at least 18 years old and have the
          legal capacity to enter into this Agreement. You may not use the
          Services if you are prohibited from doing so by law.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">3. Account and Security</h3>
        <p className="mb-4">
          You are responsible for maintaining the confidentiality of your account
          information and for all activity that occurs under your account. You
          agree to notify us immediately of any unauthorized use of your account
          or any other breach of security.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">4. Acceptable Use</h3>
        <p className="mb-4">
          You may only use the Services for their intended purpose and in
          accordance with these Terms and all applicable laws. Prohibited
          activities include, but are not limited to:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>Infringing on intellectual property rights</li>
          <li>Transmitting malware or harmful code</li>
          <li>Interfering with the Services or connected networks</li>
          <li>Misusing the Services for spam or unauthorized purposes</li>
          <li>Impersonating any person or entity</li>
          <li>Collecting personal data without consent</li>
        </ul>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">5. User Content</h3>
        <p className="mb-4">
          You are responsible for all content you submit to the Services. You
          grant us a non-exclusive, worldwide, royalty-free license to use,
          reproduce, modify, publish, distribute, and display the User Content
          in connection with the Services.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">6. Intellectual Property</h3>
        <p className="mb-4">
          The Services and all content are protected by intellectual property
          laws. You may not modify, reverse engineer, decompile, or create
          derivative works of the Services.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">
          7. Disclaimers and Limitation of Liability
        </h3>
        <p className="mb-4">
          THE SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND.
          OUR LIABILITY SHALL BE LIMITED TO THE AMOUNT PAID BY YOU FOR THE
          SERVICES.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">8. Indemnification</h3>
        <p className="mb-4">
          You agree to indemnify and hold us harmless from any claims arising
          out of your use of the Services or breach of these Terms.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">9. Termination</h3>
        <p className="mb-4">
          We may terminate your access to the Services at any time, for any
          reason, or for no reason.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">
          10. Governing Law and Dispute Resolution
        </h3>
        <p className="mb-4">
          These Terms shall be governed by the laws of [Your State/Country].
          Any disputes shall be resolved through binding arbitration in [Your
          City/State].
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">11. Amendments</h3>
        <p className="mb-4">
          We may amend these Terms at any time by posting the amended Terms on
          the Services. Your continued use of the Services after such posting
          will be deemed acceptance of the amended Terms.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">12. Privacy Policy</h3>
        <p className="mb-4">
          Our Privacy Policy explains how we collect, use, and disclose your
          personal information. You agree to be bound by our Privacy Policy.
        </p>
  
        <h3 className="text-xl font-semibold mt-4 mb-2">13. Contact Us</h3>
        <p className="mb-4">
          If you have any questions about these Terms, please contact us.
        </p>
      </div>
    );
  };
  
  export default TermsContent;