import React from 'react'
import Banner from '../Components/Home/Banner'
// import LogoSlide from '../Components/Home/LogoSlide'
import Process from '../Components/Home/Process'
import Features from '../Components/Home/Features'
// import Achieved from '../Components/Home/Achieved'
// import Testimonials from '../Components/Home/Testimonials'

const Home = () => {
    return (
        <>
            <Banner/>
            {/* <LogoSlide/> */}
            <Process/>
            <Features/>
            {/* <Achieved/> */}
            {/* <Testimonials/> */}
        </>
    )
}

export default Home