import React from 'react';

const UniversalModal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="universal-modal-overlay" onClick={onClose}>
      <div className="universal-modal-content" onClick={e => e.stopPropagation()}>
        <button className="universal-modal-close" onClick={onClose}>&times;</button>
        {title && <h2>{title}</h2>}
        {children}
      </div>
    </div>
  );
};

export default UniversalModal;