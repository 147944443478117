const PrivacyPolicyModal = () => (
    <div className="modal-body">
      <p>
        At InsightPath, we are committed to protecting your privacy and
        ensuring the security of your personal information. This policy
        outlines how we collect, use, and protect your data when you use
        our sales enablement content analysis service.
      </p>
  
      <h3>Information We Collect</h3>
      <ul>
        <li>Account Information: Details provided during signup and your email address.</li>
        <li>Content Data: Sales enablement content you upload and questions you ask.</li>
        <li>Usage Data: Information about your interactions with our platform.</li>
        <li>Correspondence: Records of communication with our support team.</li>
      </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">How We Use Your Information</h3>
          <ul className="list-disc pl-5 mb-4">
            <li>
              To provide and improve our AI-powered content analysis service.
            </li>
            <li>To generate answers based on your content and questions.</li>
            <li>To communicate product updates (opt-out available).</li>
            <li>To improve the performance for your usecase</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">Sharing of Your Information</h3>
          <p className="mb-4">
            We do not share personal information with third parties except as
            required by law. Anonymized data may be used internally to improve
            our services.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">AI Processing</h3>
          <p className="mb-4">
            We use AI models to process your content. We do not cross-train our AI
            using customer data.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Your Rights</h3>
          <p className="mb-4">
            You have the right to access, correct, or delete your personal
            information, and to opt out of marketing communications.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Changes to Our Policy</h3>
          <p className="mb-4">
            We may update this policy and will notify you of significant changes
            by posting the new policy on our website. Please contact us if you
            have any questions regarding the privacy policy
          </p>
    </div>
  );


export default PrivacyPolicyModal;